import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Card, CardBody, Col, Modal, ModalBody, Row, ModalFooter, Label, Input,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown
} from "reactstrap";
import Select from 'react-select';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from "react-i18next";
import { createJob, updateJob, getJobsById, getMyJobs, deleteJob, deactiveJob, activeJob } from "../../../api/apiJob";
import Pagination from "../../Jobs/JobList2/Pagination";
import { numberFormat } from "../../../common/checkValidate/formatMoney";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage } from "../../../common/constants/Services";
import Alerts from "../../../pages/ExtraPages/Components/Alerts";
import AlertsSuccess from "../../../pages/ExtraPages/Components/AlertsSuccess";
import Spinners from "../../../pages/ExtraPages/Components/Spinners";
import { MESSAGE_VALIDATE } from "../../../common/constants/Messages";
import ImageDefault from "../../../assets/images/featured-job/img-default.webp";
import PlaceholderLoaddingJob from "../../../common/placeholder/PlaceholderLoaddingJob";
import { checkJobPostTime } from "../../../common/checkValidate/checkJobPostTime";
import { checkJobPostExpireTime } from "../../../common/checkValidate/checkJobPostExpireTime";
import {
  jobTypeList, workingTypeList, genderList, educationLevelList, candidateTypeList,
  reviewSalaryList, experienceRequiredList, positionList, statusList
} from "../../../common/dropdownList/dropdownData";
import { checkIconCountry } from "../../../common/checkValidate/checkIconCountry";


const JobListing = (props) => {

  const { t } = useTranslation("translation");
  //Delete Modal
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  //Status Modal
  const [modalStatus, setModalStatus] = useState(false);
  const openModalStatus = () => setModalStatus(!modalStatus);

  //modalAdd
  const [modalAdd, setModalAdd] = useState(false);
  const openModalAdd = () => setModalAdd(!modalAdd);


  const didMount = useRef(false);
  const companyName = getStorage(STORAGE_KEYS.FULL_NAME);
  const companyImage = getStorage(STORAGE_KEYS.AVATAR);

  let jobDetail = '/job/';

  const customReactSelectStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      borderColor: '#eff0f2',
      height: '42.6px',
      fontWeight: 500
    }),
    menuPortal: provided => ({ ...provided, zIndex: 99 }),
  };

  const customSelectValidateStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      borderColor: 'red',
      height: '42.6px',
      fontWeight: 500
    }),
    menuPortal: provided => ({ ...provided, zIndex: 99 }),
  };

  let date = Moment(new Date).format("YYYY-MM-DD");

  let [banner, setBanner] = useState('');
  let [base64file, setBase64File] = useState('');
  let [id, setId] = useState(0);
  let [statusJob, setStatusJob] = useState(0);
  let [title, setTitle] = useState('');
  let [jobType, setJobType] = useState('');
  let [workingType, setWorkingType] = useState('');
  let [gender, setGender] = useState('');
  let [ageFrom, setAgeFrom] = useState(18);
  let [ageTo, setAgeTo] = useState(35);
  let [experienceRequired, setExperienceRequired] = useState("0");
  let [position, setPosition] = useState('1');
  let [workLocationAddress, setWorkLocationAddress] = useState('');
  let [totalWorkerNeeded, setTotalWorkerNeeded] = useState(2);
  let [salaryFrom, setSalaryFrom] = useState(500);
  let [salaryTo, setSalaryTo] = useState(1000);
  let [currency, setCurrency] = useState('VND');
  let [currencyList, setCurrencyList] = useState();
  let [dateNeeded, setDateNeeded] = useState(date);
  let [jobDescription, setJobDescription] = useState('');
  let [editorJobDescription, setEditorJobDescription] = useState(EditorState.createEmpty());
  let [jobRequired, setJobRequired] = useState('');
  let [editorJobRequired, setEditorJobRequired] = useState(EditorState.createEmpty());
  let [visaType, setVisaType] = useState();
  let [visaTypeList, setVisaTypeList] = useState();
  let [foreignLanguagesList, setForeignLanguagesList] = useState();
  let [foreignLanguagesList2, setForeignLanguagesList2] = useState();
  let [foreignLanguagesList3, setForeignLanguagesList3] = useState();
  let [educationLevel, setEducationLevel] = useState('');
  let [foreignLanguage, setForeignLanguage] = useState('');
  let [foreignLanguageLevel, setforeignLanguageLevel] = useState('');
  let [isForeignLanguage, setIsForeignLanguage] = useState(false);
  let [foreignLanguage2, setForeignLanguage2] = useState('');
  let [foreignLanguageLevel2, setforeignLanguageLevel2] = useState('');
  let [foreignLanguage3, setForeignLanguage3] = useState('');
  let [foreignLanguageLevel3, setforeignLanguageLevel3] = useState('');
  let [foreignLanguageLevelList, setForeignLanguageLevelList] = useState();
  let [foreignLanguageLevelList2, setForeignLanguageLevelList2] = useState();
  let [foreignLanguageLevelList3, setForeignLanguageLevelList3] = useState();
  let [foreignLanguageLevelAll, setForeignLanguageLevelAll] = useState();
  let [foreignLanguageAll, setForeignLanguageAll] = useState();

  let [candidateType, setCandidateType] = useState('');
  let [reviewSalary, setReviewSalary] = useState('');

  let [jobListing, setJobListing] = useState([]);
  let [jobFilter, setJobFilter] = useState([]);
  let [isUpdate, setIsUpdate] = useState(false);

  let [countryId, setCountryId] = useState(1);
  let [countryList, setCountryList] = useState([]);
  let [countryListAll, setCountryListAll] = useState();
  let [nationalityList, setNationalityList] = useState([]);
  let [cityId, setCityId] = useState(1);
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();
  let [countryFilter, setCountryFilter] = useState({ value: -1, label: 'Quốc gia' });
  let [countryFilterList, setCountryFilterList] = useState();
  let [nationality, setNationality] = useState('');
  let [industry, setIndustry] = useState('');
  let [industryList, setIndustryList] = useState();
  let [industryFilter, setIndustryFilter] = useState({ value: -1, label: 'Ngành nghề' });
  let [industryFilterList, setIndustryFilterList] = useState();
  let [field, setField] = useState('');
  let [fieldsList, setFieldsList] = useState();

  let [bonus, setBonus] = useState(false);

  let [tcAnTrua, settcAnTrua] = useState(false);
  let [tcNhaO, settcNhaO] = useState(false);
  let [tcDiLai, settcDiLai] = useState(false);
  let [tcNghiViec, settcNghiViec] = useState(false);
  let [tcKhac, settcKhac] = useState(false);
  let [contentKhac, setContentKhac] = useState('');

  let [hscv, setHSCV] = useState(false);
  let [hsbc, setHSBC] = useState(false);
  let [hstc, setHSTC] = useState(false);
  let [hstct, setHSTCT] = useState(false);

  let [hshc, setHSHC] = useState(false);
  let [hsblx, setHSBLX] = useState(false);

  let [alertsuccess, setAlertSuccess] = useState(false);
  let [alertdanger, setAlertDanger] = useState(false);
  let [alertNoData, setAlertNoData] = useState('');
  let [loading, setLoading] = useState(false);
  let [invalid, setInvalid] = useState(true);

  let [titleErr, setTitleErr] = useState('');
  let [cityErr, setCityErr] = useState('');
  let [nationalityErr, setNationalityErr] = useState('');
  let [visaTypeErr, setVisaTypeErr] = useState('');
  let [salaryFromErr, setSalaryFromErr] = useState('');
  let [salaryToErr, setSalaryToErr] = useState('');
  let [ageFromErr, setAgeFromErr] = useState('');
  let [ageToErr, setAgeToErr] = useState('');

  let [totalPage, setTotalPage] = useState(1);
  let [totalPageFilter, setTotalPageFilter] = useState(1);

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate();
  }, [title]);

  const checkValidate = () => {
    title && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0 ? setInvalid(false) : setInvalid(true);
  };

  useEffect(() => {
    setCountryId(props.countryId);
    setCountryList(props.countryList);
    setCountryListAll(props.countryListAll);
    setCountryFilterList(props.countryFilterList);

    setNationality(props.nationality);
    setNationalityList(props.nationalityList);

    setCityId(props.cityId);
    setCityList(props.cityList);
    setCityListAll(props.cityListAll);

    setIndustry(props.industry);
    setIndustryList(props.industryList);
    setIndustryFilterList(props.industryFilterList);
    setField(props.field);
    setFieldsList(props.fieldsList);
    setVisaTypeList(props.visaTypeList);

    setForeignLanguagesList(props.foreignLanguagesList);
    setForeignLanguagesList2(props.foreignLanguagesList2);
    setForeignLanguagesList3(props.foreignLanguagesList3);

    setForeignLanguage(props.foreignLanguage);
    setforeignLanguageLevel(props.foreignLanguageLevel);
    setForeignLanguage2(props.foreignLanguage2);
    setforeignLanguageLevel2(props.foreignLanguageLevel2);
    setForeignLanguage3(props.foreignLanguage3);
    setforeignLanguageLevel3(props.foreignLanguageLevel3);

    setForeignLanguageLevelList(props.foreignLanguageLevelList);
    setForeignLanguageLevelList2(props.foreignLanguageLevelList2);
    setForeignLanguageLevelList3(props.foreignLanguageLevelList3);

    setForeignLanguageLevelAll(props.foreignLanguageLevelAll);
    setForeignLanguageAll(props.foreignLanguageAll);
    setCurrency(props.currency);
    setCurrencyList(props.currencyList);

    getMyJobs(1).then((res) => {
      const job = [];
      if (res.data.length) {
        setTotalPage(res.total);
        setTotalPageFilter(res.total);
        res.data.map((item, key) => {
          job.push({
            id: item.id,
            countryImage: checkIconCountry(props.countryListAll, item.cityId),
            companyImg: companyImage ? companyImage : ImageDefault,
            jobDescription: item.title,
            companyName: item.company ? item.company : companyName,
            location: item.workLocationAddress,
            cityId: item.cityId,
            cityName: getNameCity(item.cityId),
            status: item.status,
            statusDescription: item.status == 0 ? statusList[0].label : statusList[1].label,
            catId: item.catId,
            salary: numberFormat(item.salaryFrom) + ' - ' + numberFormat(item.salaryTo) + ' ' + item.currency + '/tháng',
            fullTime: (item.jobType === 1 ? false : true),
            partTime: (item.jobType === 1 ? true : false),
            jobPostTime: checkJobPostTime(item.createdAt),
            jobPostExpireTime: checkJobPostExpireTime(item.dateNeeded),
            jobTypeText: (item.jobType === 1 ? jobTypeList[2].label : (item.jobType === 0 ? jobTypeList[1].label : jobTypeList[0].label)),
            dateNeeded: item.dateNeeded ? item.dateNeeded : "",
            badges: [],
            status: item.status
          });
        });
        setJobListing(job);
        setJobFilter(job);
        setAlertNoData('');
      } else {
        setAlertNoData('Không có kết quả tìm kiếm!');
        setTotalPage(1);
      }
    });
    setExperienceRequired(experienceRequiredList[0]);
    setPosition(positionList[6]);
    setGender(genderList[0]);
    setEducationLevel(educationLevelList[0]);
    setCandidateType(candidateTypeList[0]);
    setReviewSalary(reviewSalaryList[0]);
    setJobType(jobTypeList[1]);
    setWorkingType(workingTypeList[1]);
  }, [props]);

  const getNameCity = (cityId) => {
    let cityName = '';
    props.countryListAll && props.countryListAll.map((item, key) => {
      item.cities.map((itemCity, keyCity) => {
        if (itemCity.id == cityId) {
          cityName = itemCity.name;
        }
      })
    })
    return cityName;
  }

  const handleFilterCountry = (event) => {
    setCountryFilter({ value: event.value, label: event.label });
    if (event.value > 0) {
      let countryArr = [];
      cityListAll.map((item, key) => {
        item.map((itemcity, keycity) => {
          jobFilter.map((itemJob, keyJob) => {
            if (itemcity.id == itemJob.cityId) {
              countryArr.push(Object.assign(itemJob, { countryId: itemcity.countryID }));
            }
          });
        });
      });
      let filterJob = countryArr.filter(c => c.countryId === event.value);
      if (filterJob.length == 0) {
        setAlertNoData('Không có kết quả tìm kiếm!');
        setTotalPage(1);
      } else {
        setAlertNoData('');
        setTotalPage(filterJob.length);
      }
      setJobListing(filterJob);
    } else {
      setAlertNoData('');
      setJobListing(jobFilter);
      setTotalPage(totalPageFilter);
    }
  }

  const handleFilterIndustry = (event) => {
    setIndustryFilter({ value: event.value, label: event.label });
    if (event.value > 0) {
      let jobData = jobFilter.filter(j => j.catId == event.value);
      if (jobData.length == 0) {
        setAlertNoData('Không có kết quả tìm kiếm!');
      } else {
        setAlertNoData('');
        setTotalPage(jobData.length);
      }
      setJobListing(jobData);
    } else {
      setAlertNoData('');
      setJobListing(jobFilter);
      setTotalPage(totalPageFilter);
    }
  }

  const handleTitle = event => {
    if (event.target.value) {
      setTitleErr('');
    } else {
      setTitleErr('Nhập tên công việc!');
    }
    setTitle(event.target.value);
    event.target.value && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleJobType = event => {
    setJobType({ value: event.value, label: event.label });
  }

  const handleWorkingType = event => {
    setWorkingType({ value: event.value, label: event.label });
  }

  const handleGender = event => {
    setGender({ value: event.value, label: event.label });
  }

  const handleVisaType = event => {
    if (event.value >= 0 || event.length > 0) {
      setVisaTypeErr('');
    } else {
      setVisaTypeErr('Chọn loại visa!');
    }
    setVisaType(event);
    title && (nationality.value >= 0 || nationality.length > 0) && (event.value >= 0 || event.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleEducationLevel = event => {
    setEducationLevel({ value: event.value, label: event.label });
  }

  const handleForeignLanguage = event => {
    setForeignLanguage({ value: event.value, label: event.label, levelCode: event.levelCode });
    setforeignLanguageLevel({ value: event.value, label: event.levelName });
    const languageLevelData = [{ value: 0, label: 'Không yêu cầu' }];
    foreignLanguageAll.map((item, key) => {
      if (item.levelCode == event.levelCode) {
        languageLevelData.push({ value: item.id, label: item.levelName });
      }
    });
    setForeignLanguageLevelList(languageLevelData);
  }

  const handleForeignLanguageLevel = event => {
    setforeignLanguageLevel({ value: event.value, label: event.label });
  }

  const handleForeignLanguage2 = event => {
    setForeignLanguage2({ value: event.value, label: event.label, levelCode: event.levelCode });
    setforeignLanguageLevel2({ value: event.value, label: event.levelName });
    const languageLevelData = [{ value: 0, label: 'Không yêu cầu' }];
    foreignLanguageAll.map((item, key) => {
      if (item.levelCode == event.levelCode) {
        languageLevelData.push({ value: item.id, label: item.levelName });
      }
    });
    setForeignLanguageLevelList2(languageLevelData);
  }

  const handleForeignLanguageLevel2 = event => {
    setforeignLanguageLevel2({ value: event.value, label: event.label });
  }

  const handleForeignLanguage3 = event => {
    setForeignLanguage3({ value: event.value, label: event.label, levelCode: event.levelCode });
    setforeignLanguageLevel3({ value: event.value, label: event.levelName });
    const languageLevelData = [{ value: 0, label: 'Không yêu cầu' }];
    foreignLanguageAll.map((item, key) => {
      if (item.levelCode == event.levelCode) {
        languageLevelData.push({ value: item.id, label: item.levelName });
      }
    });
    setForeignLanguageLevelList3(languageLevelData);
  }

  const handleForeignLanguageLevel3 = event => {
    setforeignLanguageLevel3({ value: event.value, label: event.label });
  }

  const handleIsForeignLanguage = () => {
    setIsForeignLanguage(!isForeignLanguage);
  }

  const handleCandidateType = event => {
    setCandidateType({ value: event.value, label: event.label });
  }

  const handleChangeBonus = event => {
    setBonus(event.target.checked);
  }

  const handleReviewSalary = event => {
    setReviewSalary({ value: event.value, label: event.label });
  }

  const handleChangeAnTrua = event => {
    settcAnTrua(event.target.checked);
  }

  const handleChangeNhaO = event => {
    settcNhaO(event.target.checked);
  }

  const handleChangeDiLai = event => {
    settcDiLai(event.target.checked);
  }

  const handleChangeNghiViec = event => {
    settcNghiViec(event.target.checked);
  }

  const handleChangeKhac = event => {
    settcKhac(event.target.checked);
  }

  const handleContentKhac = event => {
    setContentKhac(event.target.value);
  }

  const handleChangeHSCV = event => {
    setHSCV(event.target.checked);
  }

  const handleChangeHSBC = event => {
    setHSBC(event.target.checked);
  }

  const handleChangeHSCC = event => {
    setHSTC(event.target.checked);
  }

  const handleChangeHSTCT = event => {
    setHSTCT(event.target.checked);
  }

  const handleChangeHSHC = event => {
    setHSHC(event.target.checked);
  }

  const handleChangeHSBLX = event => {
    setHSBLX(event.target.checked);
  }

  const handleExperienceRequired = event => {
    setExperienceRequired({ value: event.value, label: event.label });
  }

  const handlePosition = event => {
    setPosition({ value: event.value, label: event.label });
  }

  const handleNationality = event => {
    if (event.value >= 0 || event.length > 0) {
      setNationalityErr('');
    } else {
      setNationalityErr('Chọn quốc tịch!');
    }
    setNationality(event);
    title && (event.value >= 0 || event.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleIndustry = event => {
    setIndustry({ value: event.value, label: event.label });
    let fieldList = industryList.filter(i => i.value == event.value);
    if (fieldList.length > 0) {
      const fieldArr = [];
      fieldList[0].children.map((item, key) => {
        if (key === 0) {
          setField({ value: item.id, label: item.nameVi });
        }
        fieldArr.push({ value: item.id, label: item.nameVi });
      })
      setFieldsList(fieldArr);
    }
  }

  const handleField = event => {
    setField({ value: event.value, label: event.label });
  }

  const handleTotalWorkerNeeded = event => {
    setTotalWorkerNeeded(event.target.value);
  }

  const handleAgeFrom = event => {
    if (event.target.value > 0) {
      if (Number(event.target.value) > Number(ageTo)) {
        setAgeFromErr('Không được < tuổi đến');
      } else {
        if (!event.target.value || !ageTo) {
          setAgeFromErr('Nhập độ tuổi');
          setAgeToErr('');
        } else {
          setAgeFromErr('');
          setAgeToErr('');
        }
      }
    } else {
      setAgeFromErr('Độ tuổi phải > 0');
    }
    setAgeFrom(event.target.value);
    title && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(event.target.value) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleAgeTo = event => {
    if (event.target.value > 0) {
      if (Number(event.target.value) < Number(ageFrom)) {
        setAgeToErr('Không được < tuổi từ');
      } else {
        if (!event.target.value || !ageFrom) {
          setAgeToErr('Nhập độ tuổi');
          setAgeFromErr('');
        } else {
          setAgeToErr('');
          setAgeFromErr('');
        }
      }
    } else {
      setAgeToErr('Độ tuổi phải > 0');
    }
    setAgeTo(event.target.value);
    title && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(event.target.value) ? setInvalid(false) : setInvalid(true);
  }

  const handleSalaryFrom = value => {
    if (Number(value) > Number(salaryTo)) {
      setSalaryFromErr('Không được < mức lương đến');
    } else {
      if (!value || !salaryTo) {
        setSalaryFromErr('Nhập mức lương');
        setSalaryToErr('');
      } else {
        setSalaryFromErr('');
        setSalaryToErr('');
      }
    }
    setSalaryFrom(value);
    title && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(value) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleSalaryTo = value => {
    if (Number(value) < Number(salaryFrom)) {
      setSalaryToErr('Không được < mức lương từ');
    } else {
      if (!value || !salaryFrom) {
        setSalaryToErr('Nhập mức lương');
        setSalaryFromErr('');
      } else {
        setSalaryToErr('');
        setSalaryFromErr('');
      }
    }
    setSalaryTo(value);
    title && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(value) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleCurrency = event => {
    setCurrency({ value: event.value, label: event.label });
  }

  const handleDateNeeded = event => {
    setDateNeeded(event.target.value);
  }

  const handleJobDescription = editorState => {
    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (content.indexOf("color: rgb(18,18,18);background-color: rgb(255,255,255);") > -1) {
      content = content.replaceAll("color: rgb(18,18,18);background-color: rgb(255,255,255);", "");
    }
    setJobDescription(content);
    setEditorJobDescription(editorState);
  }

  const handleJobRequired = editorState => {
    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (content.indexOf("color: rgb(18,18,18);background-color: rgb(255,255,255);") > -1) {
      content = content.replaceAll("color: rgb(18,18,18);background-color: rgb(255,255,255);", "");
    }
    setJobRequired(content);
    setEditorJobRequired(editorState);
  }

  const handleUploadBanner = async event => {
    const bannerLoaded = URL.createObjectURL(event.target.files[0]);
    const base64 = await convertBase64(event.target.files[0]);
    let base64Data = base64.split(',');
    setBase64File(base64Data[1]);
    setBanner(bannerLoaded);
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const handleCountry = event => {
    setCountryId({ value: event.value, label: event.label, currency: event.currency, visas: event.visas });
    setCurrency({ value: event.currency, label: event.currency });
    const visaAllArr = [{ value: 0, label: 'Không yêu cầu' }];
    if (event.visas.length) {
      event.visas.map((item, key) => {
        if (key === 0) {
          setVisaType({ value: item.id, label: item.name, countryId: item.countryId });
        }
        visaAllArr.push({ value: item.id, label: item.name, countryId: item.countryId });
      })
      setVisaTypeList(visaAllArr);
    }
    setNationality({ value: event.value, label: event.label });
    const cityListArr = [];
    cityListAll.map((item, key) => {
      const citieDropdown = item.filter(c => c.countryID == event.value);
      if (citieDropdown.length > 0) {
        citieDropdown.map((itemcity, keycity) => {
          if (keycity === 0) {
            setCityId({ value: itemcity.id, label: itemcity.name });
          }
          cityListArr.push({ value: itemcity.id, label: itemcity.name });
        });
      }
    });
    setCityList(cityListArr);
    if (cityListArr.length == 0) {
      setCityErr('Chọn thành phố!');
      setCityId({ value: 0, label: '' });
    } else {
      setCityErr('');
    }
    title && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityListArr.length > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);

    let foreignLanguageData = foreignLanguageAll.filter(f => f.countryId == event.value);
    const languageListArr = [{ value: 0, label: 'Không yêu cầu' }];
    foreignLanguageData.map((item, key) => {
      let languageDefine = '';
      if (item.levelCode === "VN") {
        languageDefine = 'Tiếng Việt';
      }
      if (item.levelCode === "JP") {
        languageDefine = 'Tiếng Nhật';
      }
      if (item.levelCode === "KR") {
        languageDefine = 'Tiếng Hàn';
      }
      if (item.levelCode === "GER") {
        languageDefine = 'Tiếng Đức';
      }
      if (item.levelCode === "EN") {
        languageDefine = 'Tiếng Anh';
      }
      if (item.levelCode === "CN") {
        languageDefine = 'Tiếng Trung';
      }
      if (item.levelCode === "SA") {
        languageDefine = 'Tiếng Ả Rập';
      }
      if (item.levelCode === "RU") {
        languageDefine = 'Tiếng Nga';
      }
      languageListArr.push({ value: item.id, label: item.levelName });
      if (key === 0) {
        setForeignLanguage({ value: item.id, label: languageDefine, levelCode: item.levelCode, levelName: item.levelName });
        setforeignLanguageLevel({ value: item.id, label: item.levelName });
      }
    });
    setForeignLanguageLevelList(languageListArr);
  }

  const handleCity = event => {
    setCityId({ value: event.value, label: event.label });
  }

  const handleWorkLocationAddress = event => {
    setWorkLocationAddress(event.target.value);
  }

  const handleAddJob = () => {
    setExperienceRequired(experienceRequiredList[0]);
    setGender(genderList[0]);
    setJobType(jobTypeList[0]);
    setCandidateType(candidateTypeList[0]);
    setReviewSalary(reviewSalaryList[0]);
    setEducationLevel(educationLevelList[0]);

    visaTypeList.map((item, key) => {
      if (key === 0) {
        setVisaType({ value: item.value, label: item.label, countryId: item.countryId })
      }
    })

    setSalaryFrom(500);
    setSalaryTo(1000);

    setTitle('');
    setPosition(positionList[6]);
    setWorkLocationAddress('');
    setJobDescription('');
    setEditorJobDescription(EditorState.createEmpty());
    setJobRequired('');
    setEditorJobRequired(EditorState.createEmpty());

    setForeignLanguage({ value: 0, label: 'Không yêu cầu', levelCode: '', levelName: 'Không yêu cầu' });
    setForeignLanguage2({ value: 0, label: 'Không yêu cầu', levelCode: '', levelName: 'Không yêu cầu' });
    setForeignLanguage3({ value: 0, label: 'Không yêu cầu', levelCode: '', levelName: 'Không yêu cầu' });

    setforeignLanguageLevel({ value: 0, label: 'Không yêu cầu', levelCode: '', levelName: 'Không yêu cầu' });
    setforeignLanguageLevel2({ value: 0, label: 'Không yêu cầu', levelCode: '', levelName: 'Không yêu cầu' });
    setforeignLanguageLevel3({ value: 0, label: 'Không yêu cầu', levelCode: '', levelName: 'Không yêu cầu' });

    setBonus(true);
    settcAnTrua(false);
    settcNhaO(false);
    settcDiLai(false);
    settcNghiViec(false);
    settcKhac(false);
    setContentKhac('');

    setHSCV(false);
    setHSBC(false);
    setHSTC(false);
    setHSTCT(false);
    setHSHC(false);
    setHSBLX(false);

    setModalAdd(!modalAdd);
  };

  const loadJobData = (page) => {
    getMyJobs(page).then((result) => {
      const job = [];
      if (result.data.length) {
        setTotalPage(result.total);
        setTotalPageFilter(result.total);
        result.data.map((item, key) => {
          job.push({
            id: item.id, companyImg: companyImage,
            jobDescription: item.title,
            countryImage: checkIconCountry(props.countryListAll, item.cityId),
            companyName: item.company ? item.company : companyName,
            location: item.workLocationAddress,
            cityName: getNameCity(item.cityId),
            status: item.status,
            statusDescription: item.status == 0 ? statusList[0].label : statusList[1].label,
            salary: numberFormat(item.salaryFrom) + ' - ' + numberFormat(item.salaryTo) + ' ' + item.currency + '/tháng',
            fullTime: (item.jobType === 1 ? false : true),
            partTime: (item.jobType === 1 ? true : false),
            jobPostTime: checkJobPostTime(item.createdAt),
            jobPostExpireTime: checkJobPostExpireTime(item.dateNeeded),
            jobTypeText: (item.jobType === 1 ? jobTypeList[2].label : (item.jobType === 0 ? jobTypeList[1].label : jobTypeList[0].label)),
            badges: [],
          });
        });
        setJobListing(job);
        setJobFilter(job);
        setAlertNoData('');
      }
    });
  }

  const handleCreateJob = () => {
    let visaData = '';
    if (visaType) {
      if (visaType.length > 0) {
        visaType.map((item, key) => {
          if (key == visaType.length - 1) {
            visaData += item.value;
          } else {
            visaData += item.value + ',';
          }
        })
      } else {
        visaData = visaType.value;
      }
    }

    let nationalityData = '';
    if (nationality) {
      if (nationality.length > 0) {
        nationality.map((item, key) => {
          if (key == nationality.length - 1) {
            nationalityData += item.value;
          } else {
            nationalityData += item.value + ',';
          }
        })
      } else {
        nationalityData = nationality.value.toString();
      }
    }

    let job = {
      title: title,
      jobType: Number(jobType.value),
      gender: Number(gender.value),
      experienceRequired: Number(experienceRequired.value),
      position: position.value,
      totalWorkerNeeded: Number(totalWorkerNeeded),
      cityId: Number(cityId.value),
      workLocationAddress: workLocationAddress,
      currency: currency.value,
      salaryFrom: Number(salaryFrom),
      salaryTo: Number(salaryTo),
      ageTo: Number(ageTo),
      ageFrom: Number(ageFrom),
      dateNeeded: dateNeeded,
      jobDescription: jobDescription,
      jobRequired: jobRequired,
      visaType: visaData.toString(),
      educationLevel: Number(educationLevel.value),
      foreignLanguages: Number(foreignLanguage.value),
      foreignLanguageLevel: foreignLanguageLevel.value.toString(),
      foreignLanguages2: Number(foreignLanguage2.value),
      foreignLanguageLevel2: foreignLanguageLevel2.value.toString(),
      foreignLanguages3: Number(foreignLanguage3.value),
      foreignLanguageLevel3: foreignLanguageLevel3.value.toString(),
      candidateType: Number(candidateType.value),
      workingType: Number(workingType.value),
      bonus: bonus,
      reviewSalary: Number(reviewSalary.value),
      tcAnTrua: tcAnTrua,
      tcNhaO: tcNhaO,
      tcDiLai: tcDiLai,
      tcNghiViec: tcNghiViec,
      tcKhac: tcKhac,
      tcKhac_Content: contentKhac,
      hscv: hscv,
      hsbc: hsbc,
      hstc: hstc,
      hstct: hstct,
      hshc: hshc,
      hsblx: hsblx,
      catId2: Number(field.value),
      catId: Number(industry.value),
      base64file: base64file,
      quoctich: nationalityData
    };
    setAlertDanger(false);
    setAlertSuccess(false);
    setLoading(true);
    if (isUpdate) {
      job = {
        id: id,
        title: title,
        jobType: Number(jobType.value),
        gender: Number(gender.value),
        experienceRequired: Number(experienceRequired.value),
        position: position.value,
        totalWorkerNeeded: Number(totalWorkerNeeded),
        cityId: Number(cityId.value),
        workLocationAddress: workLocationAddress,
        currency: currency.value,
        salaryFrom: Number(salaryFrom),
        salaryTo: Number(salaryTo),
        ageTo: Number(ageTo),
        ageFrom: Number(ageFrom),
        dateNeeded: dateNeeded,
        jobDescription: jobDescription,
        jobRequired: jobRequired,
        visaType: visaData.toString(),
        educationLevel: Number(educationLevel.value),
        foreignLanguages: Number(foreignLanguage.value),
        foreignLanguageLevel: foreignLanguageLevel.value.toString(),
        foreignLanguages2: Number(foreignLanguage2.value),
        foreignLanguageLevel2: foreignLanguageLevel2.value.toString(),
        foreignLanguages3: Number(foreignLanguage3.value),
        foreignLanguageLevel3: foreignLanguageLevel3.value.toString(),
        candidateType: Number(candidateType.value),
        workingType: Number(workingType.value),
        bonus: bonus,
        reviewSalary: Number(reviewSalary.value),
        tcAnTrua: tcAnTrua,
        tcNhaO: tcNhaO,
        tcDiLai: tcDiLai,
        tcNghiViec: tcNghiViec,
        tcKhac: tcKhac,
        tcKhac_Content: contentKhac,
        hscv: hscv,
        hsbc: hsbc,
        hstc: hstc,
        hstct: hstct,
        hshc: hshc,
        hsblx: hsblx,
        catId2: Number(field.value),
        catId: Number(industry.value),
        base64file: base64file,
        quoctich: nationalityData
      };
      updateJob(job).then((res) => {
        if (res.status === 200) {
          loadJobData(1);
          setLoading(false);
          setAlertSuccess(true);
          setAlertDanger(false);
        } else {
          setLoading(false);
          setAlertDanger(true);
          setAlertSuccess(false);
        }
      });
      setIsUpdate(false);
    } else {
      createJob(job).then((res) => {
        if (res.status === 200) {
          loadJobData(1);
          setLoading(false);
          setAlertSuccess(true);
          setAlertDanger(false);
        } else {
          setLoading(false);
          setAlertDanger(true);
          setAlertSuccess(false);
        }

      }, (error) => {
        setLoading(false);
        setAlertDanger(true);
        setAlertSuccess(false);
      });
    };
    setModalAdd(!modalAdd);
  };

  const handleUpdateJob = (jobId) => {
    getJobsById(jobId).then((res) => {
      res.job.image ? setBanner(res.job.image) : setBanner('');
      res.job.title ? setTitle(res.job.title) : setTitle('');

      if (res.job.jobType) {
        let jobTypeData = jobTypeList.filter(j => j.value == res.job.jobType);
        if (jobTypeData) {
          setJobType({ value: jobTypeData[0].value, label: jobTypeData[0].label });
        }
      }

      if (res.job.workingType >= 0) {
        let workingTypeData = workingTypeList.filter(w => w.value == res.job.workingType);
        if (workingTypeData.length) {
          setWorkingType({ value: workingTypeData[0].value, label: workingTypeData[0].label });
        }
      }

      if (res.job.gender) {
        let genderData = genderList.filter(g => g.value == res.job.gender);
        if (genderData) {
          setGender({ value: genderData[0].value, label: genderData[0].label });
        }
      }

      if (res.job.experienceRequired) {
        let experienceData = experienceRequiredList.filter(e => e.value == res.job.experienceRequired);
        if (experienceData) {
          setExperienceRequired({ value: experienceData[0].value, label: experienceData[0].label });
        }
      };

      if (res.job.position) {
        let positionData = positionList.filter(p => p.value == res.job.position);
        if (positionData) {
          setPosition({ value: positionData[0].value, label: positionData[0].label });
        }
      }

      if (countryListAll) {
        const cityArr = [];
        countryListAll.map((item, key) => {
          if (key === 0 && !res.job.cityId) {
            setCountryId({ value: item.id, label: item.name });
            let cities = item.cities.filter(c => c.countryID === item.id);
            if (cities.length > 0) {
              cities.map((itemcity, keycity) => {
                if (keycity === 0) {
                  setCityId({ value: itemcity.id, label: itemcity.name });
                }
                cityArr.push({ value: itemcity.id, label: itemcity.name });
              })
              setCityList(cityArr);
            }
          }
          if (res.job.cityId) {
            item.cities.map((city, keycity) => {
              if (city.id == res.job.cityId) {
                setCityId({ value: city.id, label: city.name });
                setCountryId({ value: item.id, label: item.name });
                setCityList(cityArr);
              }
            })
          }
        })
      }
      res.job.workLocationAddress ? setWorkLocationAddress(res.job.workLocationAddress) : setWorkLocationAddress('');
      res.job.totalWorkerNeeded ? setTotalWorkerNeeded(res.job.totalWorkerNeeded) : setTotalWorkerNeeded(2);
      res.job.salaryFrom ? setSalaryFrom(res.job.salaryFrom) : setSalaryFrom(500);
      res.job.salaryTo ? setSalaryTo(res.job.salaryTo) : setSalaryTo(1000);
      res.job.ageTo ? setAgeTo(res.job.ageTo) : setAgeTo(35);
      res.job.ageFrom ? setAgeFrom(res.job.ageFrom) : setAgeFrom(18);

      if (res.job.currency) {
        setCurrency({ value: res.job.currency, label: res.job.currency });
      };

      res.job.dateNeeded ? setDateNeeded(res.job.dateNeeded) : setDateNeeded(date);
      res.job.jobDescription ? setJobDescription(res.job.jobDescription) : setJobDescription('');
      if (res.job.jobDescription) {
        const blocksFromHTML = htmlToDraft(res.job.jobDescription);
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorJobDescription(EditorState.createWithContent(content));
      };

      res.job.jobRequired ? setJobRequired(res.job.jobRequired) : setJobRequired('');
      if (res.job.jobRequired) {
        const blocksFromHTML = htmlToDraft(res.job.jobRequired);
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorJobRequired(EditorState.createWithContent(content));
      };

      if (res.job.visaType) {
        const visaTypeData = [];
        if (res.job.visaType.length > 1) {
          let visaData = res.job.visaType.split(',');
          visaData.map((item, key) => {
            let data = visaTypeList.filter(v => v.value == item);
            if (data) {
              visaTypeData.push(data[0]);
            }
          })
        } else {
          let data = visaTypeList.filter(v => v.value == res.job.visaType);
          if (data) {
            visaTypeData.push(data[0]);
          }
        }
        setVisaType(visaTypeData);
      }
      if (res.job.quoctich) {
        const nationalityData = [];
        if (res.job.quoctich.length > 1) {
          let nationalityRes = res.job.quoctich.split(',');
          nationalityRes.map((item, key) => {
            let data = nationalityList.filter(c => c.value == item);
            if (data) {
              nationalityData.push(data[0]);
            }
          })
        } else {
          let data = nationalityList.filter(c => c.value == res.job.quoctich);
          if (data) {
            nationalityData.push(data[0]);
          }
        }
        setNationality(nationalityData);
      }

      if (res.job.catId) {
        let industryData = industryList.filter(i => i.value == res.job.catId);
        if (industryData) {
          setIndustry({ value: industryData[0].value, label: industryData[0].label });
          const fieldArr = [];
          industryData[0].children.map((item, key) => {
            if (item.id === res.job.catId2) {
              setField({ value: item.id, label: item.nameVi });
            }
            fieldArr.push({ value: item.id, label: item.nameVi });
          })
          setFieldsList(fieldArr);
        }
      }

      if (res.job.educationLevel) {
        let educationLevelData = educationLevelList.filter(e => e.value == res.job.educationLevel);
        if (educationLevelData) {
          setEducationLevel({ value: educationLevelData[0].value, label: educationLevelData[0].label });
        }
      }

      if (res.job.foreignLanguages) {
        let foreignLanguageData = foreignLanguagesList.filter(p => p.value == res.job.foreignLanguages);
        if (foreignLanguageData) {
          setForeignLanguage({ value: foreignLanguageData[0].value, label: foreignLanguageData[0].label });
        }
      }
      if (res.job.foreignLanguageLevel) {
        let foreignLanguageLevelData = foreignLanguageLevelAll.filter(f => f.value == res.job.foreignLanguageLevel);
        if (foreignLanguageLevelData.length) {
          setforeignLanguageLevel({ value: foreignLanguageLevelData[0].value, label: foreignLanguageLevelData[0].label });
          let languageLevelList = foreignLanguageLevelAll.filter(f => f.levelCode == foreignLanguageLevelData[0].levelCode);
          setForeignLanguageLevelList(languageLevelList);
        }
      }

      if (res.job.foreignLanguages2) {
        let foreignLanguageData = foreignLanguagesList.filter(p => p.value == res.job.foreignLanguages2);
        if (foreignLanguageData) {
          setForeignLanguage2({ value: foreignLanguageData[0].value, label: foreignLanguageData[0].label });
        }
      }
      if (res.job.foreignLanguageLevel2) {
        let foreignLanguageLevelData = foreignLanguageLevelAll.filter(f => f.value == res.job.foreignLanguageLevel2);
        if (foreignLanguageLevelData.length) {
          setforeignLanguageLevel2({ value: foreignLanguageLevelData[0].value, label: foreignLanguageLevelData[0].label });
          let languageLevelList = foreignLanguageLevelAll.filter(f => f.levelCode == foreignLanguageLevelData[0].levelCode);
          setForeignLanguageLevelList2(languageLevelList);
        }
      }

      if (res.job.foreignLanguages3) {
        let foreignLanguageData = foreignLanguagesList.filter(p => p.value == res.job.foreignLanguages3);
        if (foreignLanguageData) {
          setForeignLanguage3({ value: foreignLanguageData[0].value, label: foreignLanguageData[0].label });
        }
      }
      if (res.job.foreignLanguageLevel3) {
        let foreignLanguageLevelData = foreignLanguageLevelAll.filter(f => f.value == res.job.foreignLanguageLevel3);
        if (foreignLanguageLevelData.length) {
          setforeignLanguageLevel3({ value: foreignLanguageLevelData[0].value, label: foreignLanguageLevelData[0].label });
          let languageLevelList = foreignLanguageLevelAll.filter(f => f.levelCode == foreignLanguageLevelData[0].levelCode);
          setForeignLanguageLevelList3(languageLevelList);
        }
      }

      if (res.job.candidateType) {
        let candidateTypeData = candidateTypeList.filter(c => c.value == res.job.candidateType);
        if (candidateTypeData) {
          setCandidateType({ value: candidateTypeData[0].value, label: candidateTypeData[0].label });
        }
      }

      res.job.bonus ? setBonus(res.job.bonus) : setBonus(false);

      if (res.job.reviewSalary) {
        let reviewSalaryData = reviewSalaryList.filter(r => r.value == res.job.reviewSalary);
        if (reviewSalaryData) {
          setReviewSalary({ value: reviewSalaryData[0].value, label: reviewSalaryData[0].label });
        }
      }

      res.job.tcAnTrua ? settcAnTrua(res.job.tcAnTrua) : settcAnTrua(false);
      res.job.tcNhaO ? settcNhaO(res.job.tcNhaO) : settcNhaO(false);
      res.job.tcDiLai ? settcDiLai(res.job.tcDiLai) : settcDiLai(false);
      res.job.tcNghiViec ? settcNghiViec(res.job.tcNghiViec) : settcNghiViec(false);
      res.job.tcKhac ? settcKhac(res.job.tcKhac) : settcKhac(false);
      res.job.tcKhac_Content ? setContentKhac(res.job.tcKhac_Content) : setContentKhac('');

      res.job.hscv ? setHSCV(res.job.hscv) : setHSCV(false);
      res.job.hsbc ? setHSBC(res.job.hsbc) : setHSBC(false);
      res.job.hstc ? setHSTC(res.job.hstc) : setHSTC(false);
      res.job.hstct ? setHSTCT(res.job.hstct) : setHSTCT(false);
      res.job.hshc ? setHSHC(res.job.hshc) : setHSHC(false);
      res.job.hsblx ? setHSBLX(res.job.hsblx) : setHSBLX(false);
    })
    setId(jobId);
    setIsUpdate(true);
    setModalAdd(!modalAdd);
  }

  const handleDeleteJob = (jobId) => {
    setId(jobId);
    openModal();
  }

  const handleConfirmDeleteJob = () => {
    let data = {
      id: id
    }
    deleteJob(data).then((res) => {
      if (res.status == 200) {
        loadJobData(1);
      }
    })
    openModal();
  }

  const handleChangeStatus = (jobId, statusJob) => {
    setId(jobId);
    setStatusJob(statusJob);
    openModalStatus();
  }

  const handleConfirmChangeStatus = () => {
    let data = {
      id: id
    }
    if (statusJob == 0) {
      deactiveJob(data).then((res) => {
        if (res.status == 200) {
          loadJobData(1);
        }
      })
    } else {
      activeJob(data).then((res) => {
        if (res.status == 200) {
          loadJobData(1);
        }
      })
    }
    openModalStatus();
  }

  const updateCurrentPage = (page) => {
    loadJobData(page);
  }

  return (
    <React.Fragment>
      {loading && <Spinners />}
      {alertsuccess && <AlertsSuccess message={MESSAGE_VALIDATE.update_profile_success} />}
      {alertdanger && <Alerts message={MESSAGE_VALIDATE.update_profile_fail} />}
      <Row className="align-items-center">
        <Col lg={6}>
          <div className="mb-4 mb-lg-0">
            <h6 className="mb-0">{t("managejob.danhsachcongviec")}</h6>
          </div>
        </Col>
        <Col lg={6}>
          <div className="candidate-list-widgets">
            <Row>
              <Col lg={3}>
                <button
                  className="btn btn-primary"
                  onClick={handleAddJob}
                >
                  + {t("managejob.them")}
                </button>
              </Col>
              <Col lg={4}>
                <div className="selection-widget">
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isMulti={false}
                    value={countryFilter}
                    placeholder={'Quốc gia...'}
                    onChange={handleFilterCountry}
                    styles={customReactSelectStyles}
                    options={countryFilterList}
                    menuPosition={'fixed'}
                  />
                </div>
              </Col>
              <Col lg={5}>
                <div className="selection-widget mt-2 mt-lg-0">
                  <Select
                    isMulti={false}
                    value={industryFilter}
                    placeholder={'Ngành nghề...'}
                    onChange={handleFilterIndustry}
                    styles={customReactSelectStyles}
                    menuPosition={'fixed'}
                    options={industryFilterList}
                    className="react-select-container"
                    classNamePrefix="react-select"

                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          {alertNoData && <div className="section-title text-center">
            <h3 className="title">{alertNoData}</h3>
          </div>}
          {(!jobListing.length && !alertNoData) ? (<PlaceholderLoaddingJob />) : (jobListing.map((jobListingDetails, key) => (
            <Card className="job-box card mt-4" key={key}>
              <CardBody className="p-4">
                <Row>
                  <Col lg={1}>
                    <Link to={jobDetail + jobListingDetails.id}>
                      <img
                        src={jobListingDetails.companyImg}
                        alt=""
                        className="img-fluid rounded-3"
                      />
                    </Link>
                  </Col>
                  <Col lg={5}>
                    <div className="mt-3 mt-lg-0">
                      <h5 className="fs-17 mb-1">
                        <Link to={jobDetail + jobListingDetails.id} className="text-dark">
                          {jobListingDetails.jobDescription}
                        </Link>
                      </h5>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <p className="text-muted fs-14 mb-0">
                            {jobListingDetails.companyName}
                          </p>
                        </li>
                        <li className="list-inline-item">
                          <p className="text-muted fs-14 mb-0">
                            <i className="mdi mdi-map-marker"></i>{" "}
                            <img src={jobListingDetails.countryImage} style={{ marginTop: -2 }} height="18" /> {jobListingDetails.cityName}
                          </p>
                        </li>
                        <li className="list-inline-item">
                          <p className="text-muted fs-14 mb-0">
                            <i className="uil uil-wallet"></i>{" "}
                            {jobListingDetails.salary}
                          </p>
                        </li>
                      </ul>
                      <div className="mt-2">
                        <span
                          className={
                            jobListingDetails.fullTime === true
                              ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                              : jobListingDetails.partTime === true
                                ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                                : jobListingDetails.freeLance === true
                                  ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                                  : jobListingDetails.internship === true
                                    ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                                    : ""
                          }
                        >
                          {jobListingDetails.jobTypeText}
                        </span>
                        <span className="text-muted mb-0">
                          <i className="uil uil-clock-three text-primary me-1"></i>{jobListingDetails.jobPostTime}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} className="align-self-center">
                    <div style={{ textAlign: "center" }}>
                      <span
                        className={jobListingDetails.status == 0 ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                          : "badge bg-warning-subtle text-warning fs-13 mt-1"}
                      >
                        {jobListingDetails.statusDescription}
                      </span>
                    </div>
                    <div className="mt-2" style={{ textAlign: "center" }}>
                      <span className="text-muted mb-0">
                        <i className="uil uil-calendar-alt text-primary me-1"></i>{jobListingDetails.jobPostExpireTime}
                      </span>
                    </div>
                  </Col>
                  <Col lg={3} className="align-self-center">
                    <ul className="list-inline mt-3 mb-0">
                      <li
                        className="list-inline-item"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={t("managejob.sua")}
                      >
                        <Link
                          onClick={() => handleUpdateJob(jobListingDetails.id)}
                          className="avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18"
                        >
                          <i className="uil uil-edit"></i>
                        </Link>
                      </li>
                      <UncontrolledButtonDropdown direction="down">
                        <DropdownToggle caret
                          type="button"
                          tag="a"
                          title={t("managejob.trangthai")}
                        >
                          <i className="avatar-sm bg-danger-subtle text-danger d-inline-block text-center rounded-circle fs-20 uil uil-trash-alt"></i>
                        </DropdownToggle>
                        <DropdownMenu container="body">
                          <DropdownItem onClick={() => handleChangeStatus(jobListingDetails.id, jobListingDetails.status)}>
                            {t("managejob.kichhoat")}
                          </DropdownItem>
                          <DropdownItem onClick={() => handleChangeStatus(jobListingDetails.id, jobListingDetails.status)}>
                            {t("managejob.khongkichhoat")}
                          </DropdownItem>
                          <DropdownItem onClick={() => handleDeleteJob(jobListingDetails.id)}>
                            {t("managejob.xoa")}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )))}
        </Col>
        <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />
      </Row>

      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered tabIndex="-1" backdrop="static">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Xóa công việc?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <ModalBody>
              <div>
                <h6 className="text-danger">
                  <i className="uil uil-exclamation-triangle"></i> Cảnh báo: Bạn có chắc chắn muốn xóa tin tuyển dụng?
                </h6>
                <p className="text-muted">
                  {" "}
                  Bài đăng việc làm của bạn sẽ bị xóa và ứng viên sẽ không
                  thể nhìn thấy.
                </p>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                onClick={openModal}
                className="btn btn-primary btn-sm"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={handleConfirmDeleteJob}
              >
                Ok, xóa
              </button>
            </div>
          </Modal>
        </div>

        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modalStatus} toggle={openModalStatus} centered tabIndex="-1" backdrop="static">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Thay đổi trạng thái công việc?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <ModalBody>
              <div>
                <h6 className="text-warning">
                  <i className="uil uil-exclamation-triangle"></i> Cảnh báo: Bạn có chắc chắn muốn thay đổi trạng thái tin tuyển dụng?
                </h6>
                <p className="text-muted">
                  {" "}
                  Bài đăng việc làm của bạn sẽ bị ẩn hoặc hiện tùy vào trạng thái thay đổi
                </p>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                onClick={openModalStatus}
                className="btn btn-primary btn-sm"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={handleConfirmChangeStatus}
              >
                Ok, thay đổi
              </button>
            </div>
          </Modal>
        </div>

        <Modal isOpen={modalAdd} toggle={openModalAdd} role="dialog" centered fullscreen
          backdrop="static"
          scrollable={true}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {t("managejob.capnhatcongviec")}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={openModalAdd}
              ></button>
            </div>
            <ModalBody>
              <div>
                <h5 className="fs-17 fw-semibold mb-3 mb-0">{t("managejob.nhapthongtindontuyen")}</h5>
                <Row>
                  <Col lg={8}>
                    <Card className="profile-sidebar me-lg-4">
                      <CardBody className="p-4">
                        <Row>
                          <Col lg={3}>
                            <div className="mb-3">
                              <div className="mb-4 profile-user">
                                <img
                                  src={banner ? banner : ImageDefault}
                                  className="rounded-3 img-thumbnail profile-img"
                                  id="profile-img"
                                  alt=""
                                />
                                <div className="p-0 rounded-3 profile-photo-edit">
                                  <Input
                                    id="profile-img-file-input"
                                    type="file"
                                    className="profile-img-file-input"
                                    onChange={handleUploadBanner}
                                  />
                                  <Label
                                    htmlFor="profile-img-file-input"
                                    className="profile-photo-edit avatar-xs"
                                  >
                                    <i className="uil uil-edit"></i>
                                  </Label>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Row style={{ width: '100%' }}>
                                <Label
                                  htmlFor="addressjob"
                                  className="form-label"
                                >
                                  {t("managejob.diachilamviec")}
                                </Label>
                                <div style={{ width: '20%' }}>
                                  <Select
                                    isMulti={false}
                                    value={countryId}
                                    placeholder={'Quốc gia...'}
                                    onChange={handleCountry}
                                    styles={customReactSelectStyles}
                                    options={countryList}
                                    className="react-select-container"
                                    classNamePrefix="react-select"

                                  />
                                </div>
                                <div style={{ width: '30%', marginLeft: -23 }}>
                                  <Select
                                    isMulti={false}
                                    value={cityId}
                                    placeholder={'Thành phố...'}
                                    onChange={handleCity}
                                    styles={cityErr ? customSelectValidateStyles : customReactSelectStyles}
                                    options={cityList}
                                    className="react-select-container"
                                    classNamePrefix="react-select"

                                  />
                                </div>
                                <Input
                                  style={{ width: '50%', marginLeft: -11 }}
                                  type="text"
                                  className="form-control"
                                  id="workLocationAddress"
                                  onChange={handleWorkLocationAddress}
                                  value={workLocationAddress}
                                  placeholder="Nhập địa chỉ làm việc"
                                />
                                {cityErr && <label style={{ color: 'red', fontSize: 14 }}>{cityErr}</label>}
                              </Row>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <label htmlFor="title" className="form-label">
                                {t("managejob.tencongviec")}
                              </label>
                              <label style={{ paddingLeft: 2, color: 'red', fontSize: 14 }}>*</label>
                              <div className="position-relative">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  onChange={handleTitle}
                                  value={title}
                                  placeholder="Nhập tên công việc"
                                  style={{ paddingLeft: '40px', borderColor: titleErr ? 'red' : '#eff0f2' }}
                                />
                                <Icon style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: '#02AF74', fontSize: '20px' }}
                                  icon="material-symbols:business-center-outline" />
                              </div>
                              {titleErr && <label style={{ color: 'red', fontSize: 14 }}>{titleErr}</label>}
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <label htmlFor="nationality" className="form-label">
                                {t("managejob.quoctich")}
                              </label>

                              <Select
                                isMulti={true}
                                value={nationality}
                                id="choices-nationality"
                                onChange={handleNationality}
                                options={nationalityList}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    height: 'auto',
                                    fontWeight: 500,
                                    borderColor: nationalityErr ? 'red' : '#eff0f2'
                                  }),
                                }}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                              {nationalityErr && <label style={{ color: 'red', fontSize: 14 }}>{nationalityErr}</label>}
                            </div>
                          </Col>
                          {experienceRequired && <Col lg={3}>
                            <div className="mb-3">
                              <Label htmlFor="experienceRequired" className="form-label">
                                {t("managejob.kinhnghiem")}
                              </Label>
                              <Select
                                isMulti={false}
                                options={experienceRequiredList}
                                value={experienceRequired}
                                id="choices-experienceRequired"
                                onChange={handleExperienceRequired}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          {jobType && <Col lg={3}>
                            <div className="mb-3">
                              <label htmlFor="jobType" className="form-label">
                                {t("managejob.loaicongviec")}
                              </label>

                              <Select
                                isMulti={false}
                                options={jobTypeList}
                                value={jobType}
                                id="choices-jobType"
                                onChange={handleJobType}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          <Col lg={3}>
                            <div className="mb-3">
                              <label htmlFor="workingType" className="form-label">
                                Hình thức làm việc
                              </label>

                              <Select
                                isMulti={false}
                                options={workingTypeList}
                                value={workingType}
                                id="choices-workingType"
                                onChange={handleWorkingType}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <label htmlFor="position" className="form-label">
                                {t("managejob.vitri")}
                              </label>

                              <Select
                                isMulti={false}
                                options={positionList}
                                value={position}
                                id="choices-position"
                                onChange={handlePosition}
                                styles={customReactSelectStyles}
                                menuPosition={'fixed'}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <label htmlFor="industry" className="form-label">
                                {t("managejob.nganhnghe")}
                              </label>

                              <Select
                                isMulti={false}
                                value={industry}
                                id="choices-industry"
                                onChange={handleIndustry}
                                placeholder={'Chọn ngành nghề...'}
                                styles={customReactSelectStyles}
                                options={industryList}
                                menuPosition={'fixed'}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>
                          <Col lg={5}>
                            <div className="mb-3">
                              <label htmlFor="position" className="form-label">
                                {t("managejob.chuyenmon")}
                              </label>

                              <Select
                                isMulti={false}
                                value={field}
                                id="choices-field"
                                onChange={handleField}
                                styles={customReactSelectStyles}
                                options={fieldsList}
                                menuPosition={'fixed'}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Row style={{ width: '100%', marginLeft: -1 }}>
                                <Label htmlFor="salary" className="form-label" style={{ marginLeft: -13 }} >
                                  {t("managejob.mucluong")}
                                </Label>
                                <CurrencyInput
                                  groupSeparator=','
                                  decimalSeparator='.'
                                  style={{ width: '35%', borderColor: salaryFromErr ? 'red' : '#eff0f2' }}
                                  id="salaryFrom"
                                  allowDecimals={false}
                                  className="form-control"
                                  value={salaryFrom}
                                  onValueChange={handleSalaryFrom}
                                  step={10}
                                />
                                <label style={{ width: '20px', marginTop: 10, marginLeft: -8 }}>~</label>
                                <CurrencyInput
                                  groupSeparator=','
                                  decimalSeparator='.'
                                  style={{ width: '35%', borderColor: salaryToErr ? 'red' : '#eff0f2' }}
                                  id="salaryTo"
                                  allowDecimals={false}
                                  className="form-control"
                                  value={salaryTo}
                                  onValueChange={handleSalaryTo}
                                  step={10}
                                />

                                <Select
                                  isMulti={false}
                                  options={currencyList}
                                  value={currency}
                                  id="choices-currency"
                                  onChange={handleCurrency}
                                  className="react-select-container w-25 p-0"
                                  styles={customReactSelectStyles}
                                  menuPosition={'fixed'}
                                  classNamePrefix="react-select"

                                />
                              </Row>
                              {salaryFromErr && <label style={{ color: 'red', fontSize: 14 }}>{salaryFromErr}</label>}
                              {salaryToErr && <label style={{ color: 'red', fontSize: 14 }}>{salaryToErr}</label>}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="mb-3">
                              <Label htmlFor="jobDescription" className="form-label">
                                {t("managejob.motacongviec")}
                              </Label>
                              <Editor
                                wrapperStyle={{
                                  borderRadius: '4px',
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                }}
                                toolbarStyle={{
                                  border: 'none',
                                  display: 'inline-brock',
                                }}
                                editorStyle={{
                                  height: 'min-content',
                                  maxHeight: '200px',
                                  minHeight: '150px',
                                  overflow: 'auto',
                                  paddingLeft: '15px',
                                }}
                                editorState={editorJobDescription}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                // localization={{
                                //   locale: 'ja',
                                // }}
                                onEditorStateChange={handleJobDescription}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label htmlFor="jobRequired" className="form-label">
                                {t("managejob.yeucautuyendung")}
                              </Label>
                              <Editor
                                wrapperStyle={{
                                  borderRadius: '4px',
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                }}
                                toolbarStyle={{
                                  border: 'none',
                                  display: 'inline-brock',
                                }}
                                editorStyle={{
                                  height: 'min-content',
                                  maxHeight: '200px',
                                  minHeight: '150px',
                                  overflow: 'auto',
                                  paddingLeft: '15px',
                                }}
                                editorState={editorJobRequired}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                // localization={{
                                //   locale: 'ja',
                                // }}
                                onEditorStateChange={handleJobRequired}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card className="profile-content-page mt-4 mt-lg-0">
                      <CardBody className="p-4">
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="dateNeeded"
                                className="form-label"
                              >
                                {t("managejob.thoigiancan")}
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                id="dateNeeded"
                                onChange={handleDateNeeded}
                                value={dateNeeded}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="totalWorkerNeeded" className="form-label">
                                {t("managejob.tongsonguoican")}
                              </Label>
                              <div className="position-relative">
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="totalWorkerNeeded"
                                  onChange={handleTotalWorkerNeeded}
                                  value={totalWorkerNeeded}
                                  placeholder="Nhập tổng số người cần làm việc"
                                  style={{ paddingLeft: '40px' }}
                                />
                                <Icon style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: '#02AF74', fontSize: '20px' }}
                                  icon="mdi:account-multiple-outline" />
                              </div>
                            </div>
                          </Col>
                          {gender && <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="gender" className="form-label">
                                {t("managejob.gioitinh")}
                              </Label>

                              <Select
                                isMulti={false}
                                options={genderList}
                                value={gender}
                                id="choices-gender"
                                onChange={handleGender}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label htmlFor="ageFrom" className="form-label">
                                Độ tuổi
                              </Label>
                              <Row style={{ width: '100%', marginLeft: -1 }}>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="ageFrom"
                                  onChange={handleAgeFrom}
                                  value={ageFrom}
                                  style={{ width: '48%', borderColor: ageFromErr ? 'red' : '#eff0f2' }}
                                />
                                <label style={{ width: '20px', marginTop: 10, marginLeft: -8 }}>~</label>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="ageTo"
                                  onChange={handleAgeTo}
                                  value={ageTo}
                                  style={{ width: '48%', borderColor: ageToErr ? 'red' : '#eff0f2' }}
                                />
                              </Row>
                              {ageFromErr && <label style={{ color: 'red', fontSize: 14 }}>{ageFromErr}</label>}
                              {ageToErr && <label style={{ color: 'red', fontSize: 14 }}>{ageToErr}</label>}
                            </div>
                          </Col>
                          {visaType && <Col lg={12}>
                            <div className="mb-3">
                              <Label htmlFor="visatype" className="form-label">
                                {t("managejob.loaivisa")}
                              </Label>

                              <Select
                                isMulti={true}
                                value={visaType}
                                id="choices-visatype"
                                onChange={handleVisaType}
                                options={visaTypeList}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: visaTypeErr ? 'red' : '#eff0f2',
                                    height: 'auto',
                                    fontWeight: 500
                                  }),
                                }}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                              {visaTypeErr && <label style={{ color: 'red', fontSize: 14 }}>{visaTypeErr}</label>}
                            </div>
                          </Col>}
                          {foreignLanguage && <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="foreignLanguage" className="form-label">
                                {t("managejob.loaingoaingu")}
                              </Label>

                              <Select
                                isMulti={false}
                                options={foreignLanguagesList}
                                value={foreignLanguage}
                                id="choices-position"
                                onChange={handleForeignLanguage}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          {foreignLanguageLevel && <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="foreignLanguageLevel" className="form-label">
                                {t("managejob.trinhdo")}
                              </Label>
                              <Select
                                isMulti={false}
                                options={foreignLanguageLevelList}
                                value={foreignLanguageLevel}
                                id="foreignLanguageLevel"
                                onChange={handleForeignLanguageLevel}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          <Col lg={12}>
                            <div className="mb-3">
                              <Link onClick={() => handleIsForeignLanguage()}>
                                <em>{t("managejob.themngoaingu")}</em>
                              </Link>
                            </div>
                          </Col>
                          {isForeignLanguage && <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="foreignLanguage2" className="form-label">
                                {t("managejob.loaingoaingu")}
                              </Label>

                              <Select
                                isMulti={false}
                                options={foreignLanguagesList2}
                                value={foreignLanguage2}
                                id="choices-position"
                                onChange={handleForeignLanguage2}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          {isForeignLanguage && <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="foreignLanguageLevel2" className="form-label">
                                {t("managejob.trinhdo")}
                              </Label>
                              <Select
                                isMulti={false}
                                options={foreignLanguageLevelList2}
                                value={foreignLanguageLevel2}
                                id="foreignLanguageLevel2"
                                onChange={handleForeignLanguageLevel2}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          {isForeignLanguage && <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="foreignLanguage3" className="form-label">
                                {t("managejob.loaingoaingu")}
                              </Label>

                              <Select
                                isMulti={false}
                                options={foreignLanguagesList3}
                                value={foreignLanguage3}
                                id="choices-position"
                                onChange={handleForeignLanguage3}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          {isForeignLanguage && <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="foreignLanguageLevel3" className="form-label">
                                {t("managejob.trinhdo")}
                              </Label>
                              <Select
                                isMulti={false}
                                options={foreignLanguageLevelList3}
                                value={foreignLanguageLevel3}
                                id="foreignLanguageLevel3"
                                onChange={handleForeignLanguageLevel3}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          {educationLevel && <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="educationLevel" className="form-label">
                                {t("managejob.captotnghiepcuoicung")}
                              </Label>

                              <Select
                                isMulti={false}
                                options={educationLevelList}
                                value={educationLevel}
                                id="choices-educationLevel"
                                onChange={handleEducationLevel}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          {candidateType && <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="candidateType" className="form-label">
                                {t("managejob.hinhthuctuyendung")}
                              </Label>

                              <Select
                                isMulti={false}
                                options={candidateTypeList}
                                value={candidateType}
                                id="choices-candidateType"
                                onChange={handleCandidateType}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          <Col lg={12}>
                            <div className="mb-3">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="bonus"
                                  onChange={handleChangeBonus}
                                  checked={bonus}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="bonus"
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {t("managejob.chedodaingo")}
                                </label>
                              </div>
                            </div>
                          </Col>
                          {bonus && reviewSalary && <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="reviewSalary" className="form-label" style={{ fontWeight: 'bold' }}>
                                {t("managejob.tangluong")}
                              </Label>
                              <Select
                                isMulti={false}
                                options={reviewSalaryList}
                                value={reviewSalary}
                                id="choices-reviewSalary"
                                onChange={handleReviewSalary}
                                styles={customReactSelectStyles}
                                className="react-select-container"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </Col>}
                          {bonus && <Col lg={12}>
                            <div>
                              <Label htmlFor="phucap" className="form-label" style={{ fontWeight: 'bold' }}>
                                {t("managejob.trocap")}
                              </Label>
                            </div>
                          </Col>}
                          {bonus && <Col lg={3}>
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="tcAnTrua"
                                onChange={handleChangeAnTrua}
                                checked={tcAnTrua}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="tcAnTrua"
                              >
                                {t("managejob.antrua")}
                              </label>
                            </div>
                          </Col>}
                          {bonus && <Col lg={3}>
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="tcNhaO"
                                onChange={handleChangeNhaO}
                                checked={tcNhaO}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="tcNhaO"
                              >
                                {t("managejob.nhao")}
                              </label>
                            </div>
                          </Col>}
                          {bonus && <Col lg={3}>
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="tcDiLai"
                                onChange={handleChangeDiLai}
                                checked={tcDiLai}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="tcDiLai"
                              >
                                {t("managejob.dilai")}
                              </label>
                            </div>
                          </Col>}
                          {bonus && <Col lg={3}>
                            <div className="mb-3">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="tcNghiViec"
                                  onChange={handleChangeNghiViec}
                                  checked={tcNghiViec}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="tcNghiViec"
                                >
                                  {t("managejob.nghiviec")}
                                </label>
                              </div>
                            </div>
                          </Col>}
                          {bonus && <Col lg={3}>
                            <div className="mb-3">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="tcKhac"
                                  onChange={handleChangeKhac}
                                  checked={tcKhac}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="tcKhac"
                                >
                                  {t("managejob.khac")}
                                </label>
                              </div>
                            </div>
                          </Col>}
                          {tcKhac && <Col lg={8}>
                            <div className="mb-3">
                              <textarea
                                type="text"
                                className="form-control"
                                id="contentKhac"
                                onChange={handleContentKhac}
                                value={contentKhac}
                              >
                              </textarea>
                            </div>
                          </Col>}
                          <Col lg={12}>
                            <div>
                              <Label htmlFor="hosoyeucau" className="form-label" style={{ fontWeight: 'bold' }}>
                                {t("managejob.hosoyeucau")}
                              </Label>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="hscv"
                                  onChange={handleChangeHSCV}
                                  checked={hscv}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="hscv"
                                >
                                  {t("managejob.cv")}
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="hsbc"
                                  onChange={handleChangeHSBC}
                                  checked={hsbc}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="hsbc"
                                >
                                  {t("managejob.bangcap")}
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="hstc"
                                  onChange={handleChangeHSCC}
                                  checked={hstc}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="hstc"
                                >
                                  {t("managejob.chungchi")}
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="hstct"
                                  onChange={handleChangeHSTCT}
                                  checked={hstct}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="hstct"
                                >
                                  {t("managejob.thecutru")}
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="hshc"
                                  onChange={handleChangeHSHC}
                                  checked={hshc}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="hshc"
                                >
                                  {t("managejob.hochieu")}
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="hsblx"
                                  onChange={handleChangeHSBLX}
                                  checked={hsblx}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="hsblx"
                                >
                                  {t("managejob.banglaixe")}
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={handleCreateJob}
                disabled={invalid}
              >
                <i className="uil uil-save"></i> {t("managejob.capnhat")}
              </button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default JobListing;
