import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Card, CardBody, Col, Modal, ModalBody, Row, ModalFooter, Label, Input,
} from "reactstrap";
import Select from 'react-select';
import { Icon } from '@iconify/react';
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { getAccountInfo, updateAvatar, changeUserName, updateImage } from "../../../api/apiAccount";
import { getMyJobs } from "../../../api/apiJob";
import { convertExperience } from "../../../common/checkValidate/convertExperience";
import { checkPhoneNumberFormat } from "../../../common/checkValidate/checkPhoneNumber";
import { phones } from "../../../common/constants/phoneValidate";
import { updateCompany } from "../../../api/apiCompany";
import { GetCountryList } from "../../../api/apiCountry";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage } from "../../../common/constants/Services";
import { numberFormat } from "../../../common/checkValidate/formatMoney";
import Alerts from "../../../pages/ExtraPages/Components/Alerts";
import AlertsSuccess from "../../../pages/ExtraPages/Components/AlertsSuccess";
import AlertsSuccessAvatar from "../../../pages/ExtraPages/Components/AlertsSuccessAvatar";
import Spinners from "../../../pages/ExtraPages/Components/Spinners";
import { MESSAGE_VALIDATE } from "../../../common/constants/Messages";
import { jobTypeList } from "../../../common/dropdownList/dropdownData";
import { checkJobPostTime } from "../../../common/checkValidate/checkJobPostTime";
import { checkIconJobPost } from "../../../common/checkValidate/checkIconJobPost";
import { checkJobPostExpireTime } from "../../../common/checkValidate/checkJobPostExpireTime";
//Import images
import featureImage from "../../../assets/images/featured-job/img-01.webp";
import ImageUrgent from "../../../assets/images/icon-job/urgent.webp";
import flagVn from "../../../assets/images/flags/vietnam.jpg";
import flagJp from "../../../assets/images/flags/japan.jpg";
import flagUs from "../../../assets/images/flags/us.jpg";
import flagGr from "../../../assets/images/flags/germany.jpg";
import flagUk from "../../../assets/images/flags/uk.jpg";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

//Import BlogImage
import blogImage1 from "../../../assets/images/blog/img-01.jpg";
import blogImage3 from "../../../assets/images/blog/img-03.jpg";
import blogImage12 from "../../../assets/images/blog/img-12.jpg";

import ApplyJobPopup from "../../ExtraPages/Components/ApplyJobPopup";

const LeftSideContent = () => {

  const { t } = useTranslation("translation");
  const companyImage = getStorage(STORAGE_KEYS.AVATAR);
  const companyName = getStorage(STORAGE_KEYS.FULL_NAME);
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  const didMount = useRef(false);

  const vocativeList = [
    { value: "Mr.", label: "Mr" },
    { value: "Ms.", label: "Ms" },
  ];

  const languageList = [
    { value: "+84", label: "Vietnam +84", image: flagVn },
    { value: "+81", label: "Japan +81", image: flagJp },
    { value: "+44", label: "United Kingdom +44", image: flagUk },
    { value: "+1", label: "United States +1", image: flagUs },
    { value: "+49", label: "Germany +49", image: flagGr },

  ]

  let jobDetail = '/job/';

  let [avatar, setAvatar] = useState('');
  let [images, setImage] = useState([blogImage1, blogImage3, blogImage12]);
  let [image1, setImage1] = useState('');
  let [image2, setImage2] = useState('');
  let [image3, setImage3] = useState('');
  let [fullname, setFullName] = useState('');
  let [username, setUserName] = useState('');
  let [industry, setIndustry] = useState('');
  let [industryList, setIndustryList] = useState();
  const [flag, setFlag] = useState(flagVn);
  let [email, setEmail] = useState('');
  let [codeArea, setCodeArea] = useState('+84');
  let [phone, setPhone] = useState('');
  let [address, setAddress] = useState('');
  let [countryId, setCountryId] = useState(1);
  let [countryList, setCountryList] = useState();
  let [cityId, setCityId] = useState(1);
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();
  let [vocative, setVocative] = useState('Mr.');
  let [contactperson, setContactPerson] = useState('');
  let [website, setWebsite] = useState('');
  let [isConfirmed, setIsConfirmed] = useState(false);
  let [facebook, setFacebook] = useState('');
  let [twitter, setTwitter] = useState('');
  let [instagram, setInstagram] = useState('');
  let [whatsapp, setWhatsapp] = useState('');
  let [aboutcompany, setAboutCompany] = useState('');
  let [editorState, setEditorState] = useState(EditorState.createEmpty());
  let [jobRecruiting, setJobRecruiting] = useState([]);

  let [alertsuccess, setAlertSuccess] = useState(false);
  let [alertavatarsuccess, setAlertAvatarSuccess] = useState(false);
  let [alertdanger, setAlertDanger] = useState(false);
  let [loading, setLoading] = useState(false);
  let [invalid, setInvalid] = useState(true);

  //check validate
  let [fullnameErr, setFullNameErr] = useState('');
  let [phoneErr, setPhoneErr] = useState('');

  //Apply Now Model
  const [modalApply, setModalApply] = useState(false);
  const openModalApply = () => setModalApply(!modalApply);

  //Lightbox
  const [photoIndex, setphotoIndex] = useState(0);
  const [isGallery, setisGallery] = useState(false);

  useEffect(() => {
    getMyJobs(1).then((res) => {
      const job = [];
      if (res.data.length) {
        res.data.map((item, key) => {
          job.push({
            id: item.id,
            companyImg: companyImage,
            jobDescription: item.title,
            experience: convertExperience(item.experienceRequired),
            companyName: item.company ? item.company : companyName,
            location: item.workLocationAddress,
            salary: numberFormat(item.salaryFrom) + ' - ' + numberFormat(item.salaryTo) + ' ' + item.currency + '/tháng',
            fullTime: (item.jobType === 1 ? false : true),
            partTime: (item.jobType === 1 ? true : false),
            jobPostTime: checkJobPostTime(item.createdAt),
            jobPostExpireTime: checkJobPostExpireTime(item.dateNeeded), 
            iconJobPost: checkIconJobPost(item.dateNeeded) == 2 ? ImageUrgent : "",
            jobTypeText: (item.jobType === 1 ? jobTypeList[2].label : (item.jobType === 0 ? jobTypeList[1].label : jobTypeList[0].label)),
            addclassNameBookmark: false,
            badges: [],
          });
        });
        setJobRecruiting(job);
      };
    });

    getAccountInfo().then((res) => {
      if (res) {
        res.avatar ? setAvatar(res.avatar) : setAvatar('');
        res.fullName ? setFullName(res.fullName) : setFullName('');
        res.userName ? setUserName(res.userName) : setUserName('');
        res.email ? setEmail(res.email) : setEmail('');
        res.address ? setAddress(res.address) : setAddress('');
        if (res.phone) {
          let phoneData = res.phone.split('|');
          phoneData[0] ? setCodeArea(phoneData[0]) : setCodeArea('+84');
          phoneData[1] ? setPhone(phoneData[1]) : setPhone('');
        }
        if (res.candidateData.contactPersonName) {
          let contact = res.candidateData.contactPersonName.split('.');
          setVocative(contact[0] + '.');
          setContactPerson(contact[1]);
        }
        res.candidateData.image1 ? setImage1(res.candidateData.image1) : setImage1('');
        res.candidateData.image2 ? setImage2(res.candidateData.image2) : setImage2('');
        res.candidateData.image3 ? setImage3(res.candidateData.image3) : setImage3('');
        let imageshow1 = res.candidateData.image1 ? res.candidateData.image1 : blogImage1;
        let imageshow2 = res.candidateData.image2 ? res.candidateData.image2 : blogImage3;
        let imageshow3 = res.candidateData.image3 ? res.candidateData.image3 : blogImage12;
        setImage([imageshow1, imageshow2, imageshow3]);
        if (res.website) {
          let websiteData = res.website.split('|');
          websiteData[0] ? setWebsite(websiteData[0]) : setWebsite("");
          websiteData[1] ? setFacebook(websiteData[1]) : setFacebook("");
          websiteData[2] ? setInstagram(websiteData[2]) : setInstagram("");
          websiteData[3] ? setTwitter(websiteData[3]) : setTwitter("");
          websiteData[4] ? setWhatsapp(websiteData[4]) : setWhatsapp("");
        }
        res.isConfirmed ? setIsConfirmed(true) : setIsConfirmed(false);
        res.about ? (setAboutCompany(res.about)) : setAboutCompany('');
        if (res.about) {
          const blocksFromHTML = htmlToDraft(res.about);
          const content = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          setEditorState(EditorState.createWithContent(content));
        }
      };

      GetCountryList().then((result) => {
        if (result.country) {
          const countryArr = [];
          const cityArr = [];
          const cityListAllArr = [];
          result.country.map((item, key) => {
            if (key === 0 && !res.cityId) {
              setCountryId({ value: item.id, label: item.name });
              let cities = item.cities.filter(c => c.countryID === item.id);
              if (cities.length > 0) {
                cities.map((itemcity, keycity) => {
                  if (keycity === 0) {
                    setCityId({ value: itemcity.id, label: itemcity.name });
                  }
                  cityArr.push({ value: itemcity.id, label: itemcity.name });
                })
                setCityList(cityArr);
              }
            }
            if (res.cityId) {
              item.cities.map((city, keycity) => {
                if (city.id == res.cityId) {
                  setCityId({ value: city.id, label: city.name });
                  setCountryId({ value: item.id, label: item.name });
                }
              })
            }
            countryArr.push({ value: item.id, label: item.name });
            cityListAllArr.push(item.cities);
          })
          setCountryList(countryArr);
          setCityListAll(cityListAllArr);
        }
        if (result.category) {
          const industryListArr = [];
          result.category.map((item, key) => {
            if (res.candidateData.industry) {
              if (item.id == res.candidateData.industry) {
                setIndustry({ value: item.id, label: item.nameVi });
              }
            }
            if (key === 0 && !res.candidateData.industry) {
              setIndustry({ value: item.id, label: item.nameVi });
            }
            industryListArr.push({ value: item.id, label: item.nameVi });
          });
          setIndustryList(industryListArr);
        }
      })
    });
  }, []);

  const handleFullName = event => {
    setFullName(event.target.value);
  }

  const handleUserName = event => {
    setUserName(event.target.value);
  }

  const handleIndustry = event => {
    setIndustry({ value: event.value, label: event.label });
  }

  const handleCodeArea = (event) => {
    setCodeArea(event.value);
  }

  const handlePhone = event => {
    setPhone(event.target.value);
  }

  const handleAddress = event => {
    setAddress(event.target.value);
  }

  const handleCountry = event => {
    setCountryId({ value: event.value, label: event.label });
    cityListAll.map((item, key) => {
      const citieDropdown = item.filter(c => c.countryID == event.value);
      if (citieDropdown.length > 0) {
        const cityListArr = [];
        citieDropdown.map((itemcity, keycity) => {
          if (keycity === 0) {
            setCityId({ value: itemcity.id, label: itemcity.name });
          }
          cityListArr.push({ value: itemcity.id, label: itemcity.name });
        });
        setCityList(cityListArr);
      }
    });
  }

  const handleCity = event => {
    setCityId({ value: event.value, label: event.label });
  }

  const handleVocative = event => {
    setVocative(event.value);
  }

  const handleContactPerson = event => {
    setContactPerson(event.target.value);
  }

  const handleWebsite = event => {
    setWebsite(event.target.value);
  }

  const handleFacebook = event => {
    setFacebook(event.target.value);
  }

  const handleInstagram = event => {
    setInstagram(event.target.value);
  }

  const handleTwitter = event => {
    setTwitter(event.target.value);
  }

  const handleWhatsApp = event => {
    setWhatsapp(event.target.value);
  }

  const handleEditorStateChange = editorState => {
    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setAboutCompany(content);
    setEditorState(editorState);

  }

  const handleUploadAvatar = event => {
    const avatarLoaded = URL.createObjectURL(event.target.files[0]);
    setAvatar(avatarLoaded);

    updateAvatar(event.target.files[0]).then((result) => {
      if (result.status == 200) {
        setAlertAvatarSuccess(true);
      } else {
        setAlertAvatarSuccess(false);
      }
    });
  }

  const handleUploadImage1 = event => {
    const imageLoaded = URL.createObjectURL(event.target.files[0]);
    setImage1(imageLoaded);

    updateImage(event.target.files[0], 1).then((result) => {

    });
  }

  const handleUploadImage2 = event => {
    const imageLoaded = URL.createObjectURL(event.target.files[0]);
    setImage2(imageLoaded);

    updateImage(event.target.files[0], 2).then((result) => {

    });
  }

  const handleUploadImage3 = event => {
    const imageLoaded = URL.createObjectURL(event.target.files[0]);
    setImage3(imageLoaded);

    updateImage(event.target.files[0], 3).then((result) => {
    });
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('fullname');
  }, [fullname]);

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('phone');
  }, [phone]);

  const checkValidate = (value) => {
    let checkFullName = true;
    let checkPhone = true;
    if (value === 'fullname') {
      if (!fullname) {
        setFullNameErr('Vui lòng nhập họ tên!');
        checkFullName = true;
      } else {
        setFullNameErr('');
        checkFullName = false;
      }
      !checkPhoneNumberFormat(phone) ? checkPhone = true : checkPhone = false;
    }
    if (value === 'phone') {
      if ((!phone && !checkPhoneNumberFormat(phone, phones["vi-VN"])) || phone.length > 10 || phone.length < 9) {
        setPhoneErr('Định dạng SĐT chưa chính xác!');
        checkPhone = true;
      } else {
        setPhoneErr('');
        checkPhone = false;
      }
      !fullname ? checkFullName = true : checkFullName = false;
    }
    (checkFullName || checkPhone) ? setInvalid(true) : setInvalid(false);
  }

  const handleModal = () => {
    setModal(!modal);
  }

  const handleUpdateProfile = () => {
    let candidateInfo = {
      industry: industry.value.toString(),
      contactPersonName: vocative + '' + contactperson,
      image1: image1,
      image2: image2,
      image3: image3,
    };
    let companyInfo = {
      fullName: fullname,
      phone: codeArea + '|' + phone,
      address: address,
      cityId: Number(cityId.value),
      website: website + '|' + facebook + '|' + instagram + '|' + twitter + '|' + whatsapp,
      about: aboutcompany,
      candidateInfo
    };
    let data = {
      shortName: username
    };
    setAlertDanger(false);
    setAlertSuccess(false);
    setAlertAvatarSuccess(false);
    setLoading(true);

    updateCompany(companyInfo).then((res) => {
      if (res.status === 200) {
        if (username) {
          changeUserName(data).then((result) => {
          });
        }
        setLoading(false);
        setAlertSuccess(true);
        setAlertDanger(false);
      } else {
        setLoading(false);
        setAlertDanger(true);
        setAlertSuccess(false);
      }
    }, (error) => {
      setLoading(false);
      setAlertDanger(true);
      setAlertSuccess(false);
    });
    setModal(!modal);
  }

  return (
    <React.Fragment>
      <Col lg={4}>
        {loading && <Spinners />}
        {alertavatarsuccess && <AlertsSuccessAvatar message={MESSAGE_VALIDATE.update_avatar_success} />}
        {alertsuccess && <AlertsSuccess message={MESSAGE_VALIDATE.update_profile_success} />}
        {alertdanger && <Alerts message={MESSAGE_VALIDATE.update_profile_false} />}
        <Card className="side-bar">
          <CardBody className="p-4">
            <div className="candidate-profile text-center">
              <div className="text-center">
                <div className="mb-4 profile-user">
                  <img
                    src={avatar ? avatar : featureImage}
                    className="rounded-circle img-thumbnail profile-img"
                    id="profile-img"
                    alt=""
                  />
                  <div className="p-0 rounded-circle profile-photo-edit">
                    <Input
                      id="profile-img-file-input"
                      type="file"
                      className="profile-img-file-input"
                      onChange={handleUploadAvatar}
                    />
                    <Label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit avatar-xs"
                    >
                      <i className="uil uil-edit"></i>
                    </Label>
                  </div>
                </div>
              </div>
              <h6 className="fs-18 mb-1 mt-4">{fullname}</h6>
              <ul className="candidate-detail-social-menu list-inline mb-0">
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-twitter-alt"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-whatsapp"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-phone-alt"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </CardBody>

          <CardBody className="candidate-profile-overview border-top p-4">
            <h6 className="fs-17 fw-semibold mb-3">{t("companydetail.tongquan")}</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("companydetail.nganhnghe")}</label>
                  <div>
                    <p className="text-muted mb-0">{industry.label}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("companydetail.diachi")}</label>
                  <div>
                    <p className="text-muted mb-0">{address}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("companydetail.website")}</label>
                  <div>
                    <p className="text-muted text-break mb-0">
                      {website}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("companydetail.sodienthoai")}</label>
                  <div>
                    <p className="text-muted text-break mb-0">
                      {'(' + codeArea + ') ' + phone}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("signup.email")}</label>
                  <div>
                    <p className="text-muted text-break mb-0">
                      {email}  {isConfirmed ? <Icon style={{ color: 'green' }} icon="formkit:check" /> : <Icon style={{ color: 'red' }} icon="fa:close" />}
                    </p>
                  </div>

                </div>
              </li>
            </ul>
            <div className="mt-3">
              <Link onClick={handleModal} className="btn btn-danger btn-hover w-100">
                <i className="uil uil-edit"></i> {t("companydetail.chinhsua")}
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>

      {isGallery ? (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          enableZoom={true}
          onCloseRequest={() => {
            setisGallery(false);
          }}
          onMovePrevRequest={() => {
            setphotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % images.length);
          }}
          imageCaption={"Project " + parseFloat(photoIndex + 1)}
        />
      ) : null}
      <Col lg={8}>
        <Card className="ms-lg-4 mt-4 mt-lg-0">
          <CardBody className="p-4">
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">{t("companydetail.gioithieu")}</h6>
              <div dangerouslySetInnerHTML={{ __html: aboutcompany }} />
            </div>
            <div className="candidate-portfolio mb-5">
              <h6 className="fs-17 fw-semibold mb-4">{t("companydetail.hinhanhcongty")}</h6>
              <Row className="g-3">
                <Col lg={4}>
                  <div className="mb-4 profile-user1">
                    <img
                      src={image1 ? image1 : blogImage1}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(0);
                      }}
                      alt=""
                      style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                    />
                    <div className="p-0 rounded-3 profile-photo1-edit">
                      <Input
                        id="profile-img1-file-input"
                        type="file"
                        className="profile-img1-file-input"
                        onChange={handleUploadImage1}
                      />
                      <Label
                        htmlFor="profile-img1-file-input"
                        className="profile-photo1-edit avatar-xs"
                      >
                        <i className="uil uil-edit"></i>
                      </Label>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-4 profile-user2">
                    <img
                      src={image2 ? image2 : blogImage3}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(1);
                      }}
                      alt=""
                      style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                    />
                    <div className="p-0 rounded-3 profile-photo1-edit">
                      <Input
                        id="profile-img2-file-input"
                        type="file"
                        className="profile-img2-file-input"
                        onChange={handleUploadImage2}
                      />
                      <Label
                        htmlFor="profile-img2-file-input"
                        className="profile-photo2-edit avatar-xs"
                      >
                        <i className="uil uil-edit"></i>
                      </Label>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-4 profile-user3">
                    <img
                      src={image3 ? image3 : blogImage12}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(2);
                      }}
                      alt=""
                      style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                    />
                    <div className="p-0 rounded-3 profile-photo3-edit">
                      <Input
                        id="profile-img3-file-input"
                        type="file"
                        className="profile-img3-file-input"
                        onChange={handleUploadImage3}
                      />
                      <Label
                        htmlFor="profile-img3-file-input"
                        className="profile-photo3-edit avatar-xs"
                      >
                        <i className="uil uil-edit"></i>
                      </Label>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h6 className="fs-17 fw-semibold mb-4">{t("companydetail.vieclamdangtuyendung")}</h6>
              {jobRecruiting && jobRecruiting.map((jobRecruitingDetails, key) => (
                <div
                  key={key}
                  className={
                    jobRecruitingDetails.addclassNameBookmark === true
                      ? "job-box bookmark-post card mt-4"
                      : "job-box card mt-4"
                  }
                >
                  {
                    jobRecruitingDetails.iconJobPost && <div className="bookmark-label text-center">
                      <Link to="#" className="text-white align-middle">
                        <img
                          src={jobRecruitingDetails.iconJobPost}
                          alt=""
                          className="img-fluid rounded-3"
                          style={{ width: '50px', height: '40px', objectFit: 'cover' }}
                        />
                      </Link>
                    </div>
                  }
                  <div className="p-4">
                    <Row>
                      <Col md={2}>
                        <Link to="/companydetails">
                          <img
                            src={jobRecruitingDetails.companyImg}
                            alt=""
                            className="img-fluid rounded-3"
                            style={{ width: '100px', height: '80px', objectFit: 'cover' }}
                          />
                        </Link>
                      </Col>
                      <Col md={10}>
                        <div className="mt-3 mt-lg-0">
                          <h5 className="fs-17 mb-1">
                            <Link to={jobDetail + jobRecruitingDetails.id} className="text-dark">
                              {jobRecruitingDetails.jobDescription}
                            </Link>{" "}
                            <small className="text-muted fw-normal">
                              ({jobRecruitingDetails.experience})
                            </small>
                          </h5>
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                              <p className="text-muted fs-14 mb-0">
                                {jobRecruitingDetails.companyName}
                              </p>
                            </li>
                            <li className="list-inline-item">
                              <p className="text-muted fs-14 mb-0">
                                <i className="mdi mdi-map-marker"></i>
                                {jobRecruitingDetails.location}
                              </p>
                            </li>
                            <li className="list-inline-item">
                              <p className="text-muted fs-14 mb-0">
                                <i className="uil uil-wallet"></i>{" "}
                                {jobRecruitingDetails.salary}
                              </p>
                            </li>
                          </ul>
                          <div className="mt-2">
                            <span
                              className={
                                jobRecruitingDetails.fullTime === true
                                  ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                                  : jobRecruitingDetails.partTime === true
                                    ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                                    : jobRecruitingDetails.freeLance === true
                                      ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                                      : jobRecruitingDetails.internship === true
                                        ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                                        : ""
                              }
                            >
                              {jobRecruitingDetails.jobTypeText}
                            </span>
                            {(jobRecruitingDetails.badges || []).map(
                              (badgeInner, key) => (
                                <span
                                  className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                                  key={key}
                                >
                                  {badgeInner.badgeName}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="favorite-icon">
                      <Link to="#">
                        <i className="uil uil-heart-alt fs-18"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="p-3 bg-light">
                    <div className="row justify-content-between">
                      <Col md={3}>
                      </Col>
                      <Col md={3}>
                        <div>
                          <p className="text-muted mb-0">
                            <i className="uil uil-clock-three text-primary me-1"></i> {jobRecruitingDetails.jobPostTime}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <p className="text-muted mb-0">
                            <i className="uil uil-calendar-alt text-primary me-1"></i> {jobRecruitingDetails.jobPostExpireTime}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="text-md-end">
                          <Link
                            to=""
                            onClick={openModalApply}
                            className="primary-link"
                          >
                            {t("companydetail.ungtuyen")}{" "}
                            <i className="mdi mdi-chevron-double-right"></i>
                          </Link>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              ))}
              <div
                className="modal fade"
                id="applyNow"
                tabIndex="-1"
                aria-labelledby="applyNow"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <ApplyJobPopup modalState={modalApply} toggleModal={openModalApply} />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Modal isOpen={modal} toggle={openModal} role="dialog" centered size="xl"
        backdrop="static"
        scrollable={true}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t("companydetail.capnhatthongtinnhatuyendung")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={openModal}
            ></button>
          </div>
          <ModalBody>
            <div>
              <Row>
                <Col lg={3}>
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      {t("companydetail.tencongty")}
                    </label>
                    <label style={{ paddingLeft: 2, color: 'red', fontSize: 14 }}>*</label>
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        id="firstName"
                        onChange={handleFullName}
                        value={fullname}
                        style={{ paddingLeft: 36 }}
                      />
                      <Icon
                        style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: '#02AF74', fontSize: '20px' }}
                        icon="ri:building-line" />
                    </div>
                    {fullnameErr && <label style={{ color: 'red', fontSize: 14 }}>{fullnameErr}</label>}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      {t("companydetail.taousername")}
                    </label>
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        id="username"
                        onChange={handleUserName}
                        value={username}
                        style={{ paddingLeft: 36 }}
                      />
                      <Icon
                        style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: '#02AF74', fontSize: '20px' }}
                        icon="mdi:user-outline" />
                    </div>
                    {fullnameErr && <label style={{ color: 'red', fontSize: 14 }}>{fullnameErr}</label>}
                  </div>
                </Col>
                <Col lg={4}>
                  <Row style={{ width: '100%' }}>
                    <label htmlFor="phone" className="form-label">
                      {t("companydetail.sodienthoai")}
                    </label>
                    <div className="d-flex">
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"

                        options={languageList}
                        isMulti={false}
                        defaultValue={{ value: codeArea, label: flagVn, image: flagVn }} // Include the image path here
                        id="code-area"
                        onChange={handleCodeArea}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: '#eff0f2',
                            height: '42.6px',
                            fontWeight: 500,
                            width: '86px'
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            width: "250px",
                          }),

                        }}
                        getOptionLabel={e => (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={e.image} width="24px" height="16px" alt={e.label} /> {/* Ensure alt attribute for accessibility */}
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                      />
                      <Input
                        type="text"
                        className="form-control"
                        id="phone"
                        onChange={handlePhone}
                        value={phone}
                      />
                    </div>

                    {phoneErr && <label style={{ color: 'red', fontSize: 14 }}>{phoneErr}</label>}
                  </Row>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <label htmlFor="industry" className="form-label">
                      {t("companydetail.nganhnghe")}
                    </label>
                    <div className="position-relative">
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"

                        isMulti={false}
                        id="industry"
                        value={industry}
                        placeholder={'Chọn ngành nghề...'}
                        onChange={handleIndustry}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: '#eff0f2',
                            height: '42.6px',
                            paddingLeft: '28px',
                            fontWeight: 500
                          }),
                        }}
                        options={industryList}
                      />
                      <Icon style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: '#02AF74', fontSize: '20px' }}
                        icon="material-symbols:business-center-outline" />
                    </div>
                  </div>
                </Col>
                <Col lg={9}>
                  <div className="mb-3">
                    <Row style={{ width: '100%' }}>
                      <Label
                        htmlFor="countryId"
                        className="form-label"
                      >
                        {t("companydetail.diachi")}
                      </Label>
                      <div style={{ width: '20%' }}>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"

                          isMulti={false}
                          value={countryId}
                          placeholder={'Quốc gia...'}
                          onChange={handleCountry}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500
                            }),
                          }}
                          options={countryList}
                        />
                      </div>
                      <div style={{ width: '30%', marginLeft: -23 }}>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"

                          isMulti={false}
                          value={cityId}
                          placeholder={'Thành phố...'}
                          onChange={handleCity}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: '#eff0f2',
                              height: '42.6px',
                              fontWeight: 500
                            }),
                          }}
                          options={cityList}
                        />
                      </div>
                      <Input
                        style={{ width: '50%', marginLeft: -11 }}
                        type="text"
                        className="form-control"
                        id="address"
                        onChange={handleAddress}
                        value={address}
                        placeholder="Nhập địa chỉ"
                      />
                    </Row>
                  </div>
                </Col>
                <Col lg={3}>
                  <Row style={{ width: '100%', marginLeft: -1 }}>
                    <Label htmlFor="vocative" className="form-label" style={{ marginLeft: -13 }} >
                      {t("companydetail.lienhe")}
                    </Label>
                    <div className="d-flex p-0">
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={vocativeList}
                        isMulti={false}
                        id="choices-vocative"
                        defaultValue={{ label: "Mr", value: vocative }}
                        onChange={handleVocative}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: '#eff0f2',
                            width: '80px',
                            height: '100%',
                          }),
                        }}
                      />
                      <Input
                        style={{ width: '70%' }}
                        type="text"
                        className="form-control"
                        id="contactperson"
                        onChange={handleContactPerson}
                        value={contactperson}
                      />
                    </div>
                  </Row>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <Label htmlFor="website" className="form-label">
                      {t("companydetail.website")}
                    </Label>
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        id="website"
                        onChange={handleWebsite}
                        value={website}
                        style={{ paddingLeft: 36 }}
                      />
                      <Icon
                        style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: '#02AF74', fontSize: '20px' }}
                        icon="bx:bx-world" />
                    </div>

                  </div>
                </Col>

                <Col lg={3}>
                  <div className="mb-3">
                    <Label htmlFor="facebook" className="form-label">
                      {t("companydetail.facebook")}
                    </Label>
                    <div className="position-relative">
                      <Input
                        style={{ paddingLeft: 40 }}
                        type="text"
                        className="form-control"
                        id="facebook"
                        onChange={handleFacebook}
                        value={facebook}
                      />
                      <Icon style={{
                        position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                        fontSize: '22px'
                      }} icon="mdi:facebook" />
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <Label htmlFor="instagram" className="form-label">
                      {t("companydetail.instagram")}
                    </Label>
                    <div className="position-relative">
                      <Input
                        style={{ paddingLeft: 40 }}
                        type="text"
                        className="form-control"
                        id="instagram"
                        onChange={handleInstagram}
                        value={instagram}
                      />
                      <Icon style={{
                        position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                        fontSize: '22px'
                      }} icon="mdi:instagram" />
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <Label htmlFor="twitter" className="form-label">
                      {t("companydetail.twitter")}
                    </Label>
                    <div className="position-relative">
                      <Input
                        style={{ paddingLeft: 40 }}
                        type="text"
                        className="form-control"
                        id="twitter"
                        onChange={handleTwitter}
                        value={twitter}
                      />
                      <Icon style={{
                        position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                        fontSize: '22px'
                      }} icon="mdi:twitter" />
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="mb-3">
                    <Label htmlFor="whatsapp" className="form-label">
                      {t("companydetail.whatsapp")}
                    </Label>
                    <div className="position-relative">
                      <Input
                        style={{ paddingLeft: 40 }}
                        type="text"
                        className="form-control"
                        id="whatsapp"
                        onChange={handleWhatsApp}
                        value={whatsapp}
                      />
                      <Icon style={{
                        position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green',
                        fontSize: '22px'
                      }} icon="mdi:whatsapp" />
                    </div>
                  </div>
                </Col>
                <Col lg={9}>
                  <div className="mb-3">
                    <Label htmlFor="aboutcompany" className="form-label">
                      {t("companydetail.gioithieu")}
                    </Label>
                    <div className="position-relative">
                      <Editor
                        wrapperStyle={{
                          borderRadius: '4px',
                          border: '1px solid rgba(0, 0, 0, 0.23)',
                        }}
                        toolbarStyle={{
                          border: 'none',
                          display: 'inline-brock',
                        }}
                        editorStyle={{
                          height: 'min-content',
                          maxHeight: '200px',
                          minHeight: '150px',
                          overflow: 'auto',
                          paddingLeft: '15px',
                        }}
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        // localization={{
                        //   locale: 'ja',
                        // }}
                        onEditorStateChange={handleEditorStateChange}
                      />
                    </div>

                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={handleUpdateProfile}
              disabled={invalid}
            >
              <i className="uil uil-save"></i> {t("companydetail.capnhat")}
            </button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default LeftSideContent;